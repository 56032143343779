import React, { useEffect, useState } from "react";
import "./HeaderModal.css";
import { Modal } from "react-bootstrap";
// import { LinkData } from "Utils/static";
import dropBtn from "../../../src/assets/dropBtn.svg"
import { Link, useLocation, useNavigate } from "react-router-dom";
function HeaderModal(props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
 const LinkData = [
  // {
  //   name: "Home",
  //   link: "/",
  //  },
  {
    name: "Home For Sale",
    link: "/home-for-sale",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Gallery",
    link: "/gallery",
     children: [
      {
        name: "Gallery",
        link: "/gallery",
      },
      {
        name: "Virtual Tour",
        link: "/virtual-tour",
      },
    ],
  },
  {
    name: "Home Financing ",
    link: "/financing",
    children: [
      {
        name: "Financing",
        link: "/financing",
      },
      {
        name: "Promotions",
        link: "/promotions",
      },
    ],
  },
  // {
  //  name: "Testimonials",
  //  link: "/testimonials",
  //  },
  {
   name: "Contact Us",
   link: "/contact-us",
   },
   {
   name: "Privacy Policy",
   link: "/privacy-policy",
   },
    {
   name: "Cookies",
   link: "/cookies-policy",
   },
];

   const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

const handleSubMenuToggle = (index) => {
    setActiveSubMenu(index);
    setIsSubMenuOpen(true);
  };

  const handleSubMenuClose = () => {
    setActiveSubMenu(null);
    setIsSubMenuOpen(false);
  };
  return (
    <>
      <Modal
        show={props.isMenuOpen}
        onHide={props.toggle}
        dialogClassName="header-modal"
      >
        <Modal.Header>
          <Modal.Title>
            <div className="header-modal-heading">
              <div
                className="closeSymbol"
                onClick={() => {
                  props.toggle();
                }}
              >
                X
              </div>
              <div className="menuLabel">Menu</div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="headerLinks">
               {LinkData?.map((menuItem, idx) => (
          <React.Fragment key={idx}>
            {menuItem.children ? (
              <div
                className="mobileSubmenu"
                onMouseEnter={() => handleSubMenuToggle(idx)}
                onMouseLeave={handleSubMenuClose}
              >
                <span
                  className={`cursor-pointer flex md:px-6 text-white text-sm font-normal uppercase hoverColor ${
                    pathname === menuItem.link
                      ? "currentActive"
                      : ""
                  }`}
                >
                  {menuItem.name}{" "}
                  <span className="text-white">
                    <img
                      src={dropBtn}
                      style={{ marginLeft: "5px" }}
                      alt="dropdown"
                      title="dropdown"
                    />
                  </span>
                </span>
                <div
                  className={`mobileheaderSubmenu ${
                    isSubMenuOpen && activeSubMenu === idx
                      ? "block"
                      : "hidden"
                  } bg-black text-white space-y-2`}
                >
                  {menuItem.children.map((child, childIdx) => (
                    <Link
                      to={child.link}
                      relative="path"
                      key={childIdx}
                      className="block text-white text-sm hover:text-gray-500"
                    >
                      {child.name}
                    </Link>
                  ))}
                </div>
              </div>
            ) : (
               <Link
                to={menuItem.link}
                relative="path"
                className={
                  pathname === menuItem.link
                    ? "active currentActive px-2 md:px-6 text-white text-sm font-normal uppercase"
                    : "px-2 md:px-6 text-white text-sm font-normal uppercase"
                }
              >
                {menuItem.name}
              </Link>
            )}
          </React.Fragment>
        ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default HeaderModal;
