import React, { createContext, useContext, useState } from 'react';
import axios from '../Utils/APIS/axiosConfig';
import axiosWeb from '../Utils/APIS/axiosWebConfig'

const PageContext = createContext();

export const usePageContext = () => useContext(PageContext);

export const PageContextProvider = ({ children }) => {
    const [pageContent, setPageContent] = useState(null)
  
    const getPageContent = () => {
        const config = {
          method: 'get',
          url: `getPageContent`,
        };
        return axiosWeb.request(config);
      };
    


    const contextValue = {
      getPageContent,
      pageContent, setPageContent,

    };
  
    return (
      <PageContext.Provider value={contextValue}>
        {children}
      </PageContext.Provider>
    );
  };
