import React from "react";
import { Suspense } from "react";
import CustomRouter from "Component/CustomBrowserRouter";
import history from "Component/CustomBrowserRouter/history";
import AppRoutes from "routes/AppRoutes";
import { useGlobalContext } from './../src/context/context';
import { Route, Routes } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";


const App = () => {
  const {isAuthenticated} = useGlobalContext()
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <div className="h-screen">
        <CustomRouter history={history}>        
          <AppRoutes />
        </CustomRouter>
        <ToastContainer className="custom-toast-container"
          position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        draggable={false}
        pauseOnVisibilityChange
        closeOnClick
        pauseOnHover
        />
      </div>
    </Suspense>
  );
};

export default App;
