import React from "react";

const index = ({ title, className }) => {
  return (
   <div className={`${className} text-grey-1 text-sm font-normal uppercase vertical-title`}>
      {title}
    </div>
  );
};

export default index;
