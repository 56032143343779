import React from "react";

const Index = ({ buttonText, onClickHandler, className, disabled = false, type, href }) => {
  return (
    <button
      href={href}
      className={`text-white bg-primary uppercase px-8 py-3 cursor-pointer w-fit ${className}`}
      onClick={() => {
        onClickHandler();
      }}
      disabled={disabled}
      type={type}
    >
      {buttonText}
    </button>
  );
};

export default Index;
