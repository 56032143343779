import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "tailwindcss/tailwind.css";
import App from "./App";
import { AppProvider } from 'context/context';
import { SettingsProvider } from 'context/settingsContext';
import { ContactProvider } from "context/contactContext";
import { PageContextProvider } from "context/pageContext";
import { PortfolioContextProvider } from "context/portfolioContext";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
     <AppProvider>
      <PageContextProvider>
        <SettingsProvider>
          <ContactProvider>
            <PortfolioContextProvider>
              <App />
            </PortfolioContextProvider>
          </ContactProvider>
        </SettingsProvider>
      </PageContextProvider>
    </AppProvider>
  </React.StrictMode>
);
