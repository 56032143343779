import { Link, useLocation } from 'react-router-dom'
import "./breadcrumbs.css";

function Breadcrumbs() {
  const location = useLocation();


  return (
    <div className='container'>
    <nav className='customBreadcrumb '>
      <Link to="/"
        className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
        Home
      </Link>
      {location.pathname.startsWith("/about-us") && <>
      <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows' />
      <Link to="/about-us"
        className={location.pathname.startsWith("/about-us") ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
        About Us
        </Link></>}
        {location.pathname.startsWith("/home-for-sale") && <>
      <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows' />
      <Link to="/home-for-sale"
        className={location.pathname.startsWith("/home-for-sale") ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Home for sale
        </Link></>}
       {location.pathname.startsWith("/services") && <>
            <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows' />
      <Link to="/services"
        className={location.pathname === "/services" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Services
          </Link>
        </>}
        {location.pathname.startsWith("/gallery") && <>
             <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows' />
      <Link to="/gallery"
        className={location.pathname === "/gallery" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Gallery
        </Link></>}
       {location.pathname.startsWith("/contact-us") && <>
        <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows' />
      <Link to="/contact-us"
        className={location.pathname === "/contact-us" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Contact Us
        </Link></>}
      {location.pathname.startsWith("/financing") && <>
        <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows' />
      <Link to="/financing"
        className={location.pathname === "/financing" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Financing
      </Link></>}
      {location.pathname.startsWith("/promotions") && <>
        <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows' />
      <Link to="/promotions"
        className={location.pathname === "/promotions" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Promotions
        </Link></>}
       {location.pathname.startsWith("/testimonials") && <>
        <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows'/>
      <Link to="/testimonials"
        className={location.pathname === "/testimonials" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Testimonials
        </Link></>}
         {location.pathname.startsWith("/virtual-tour") && <>
        <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows'/>
      <Link to="/virtual-tour"
        className={location.pathname === "/virtual-tour" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Virtual Tour
          </Link></>}
         {location.pathname.startsWith("/privacy-policy") && <>
        <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows'/>
      <Link to="/privacy-policy"
        className={location.pathname === "/privacy-policy" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Privacy Policy
          </Link></>}
         {location.pathname.startsWith("/cookies-policy") && <>
        <img className='breadcrumb-arrow' src="/img/arrows.svg" alt='arrows' title='arrows'/>
      <Link to="/cookies-policy"
        className={location.pathname === "/cookies-policy" ? "breadcrumb-active" : "breadcrumb-not-active"}
      >
       Cookies
      </Link></>}
      </nav>
      </div>
  );
}

export default Breadcrumbs;