import React, { createContext, useContext, useState,useEffect } from 'react';
import axios from '../Utils/APIS/axiosConfig';
import axiosWeb from '../Utils/APIS/axiosWebConfig'
import { set } from 'react-hook-form';
import { toast } from "react-toastify";


const ContactContext = createContext();

export const useContactContext = () => useContext(ContactContext);

export const ContactProvider = ({ children }) => {
    const [contactData, setContactData] = useState([]);
    const [selectedID, setSelectedID] = useState(null);
    const [message, setMessage] = useState('');
    const [fetchData, setFetchData] = useState(false)

    const closeModal = ()=>{
        setSelectedID(null)
    }
    const openModal =(_id) =>{
        setSelectedID(_id);
      }
    
    const sendHandler = async () => {
        try {
          await postContactData(selectedID, message);
          setMessage()
          closeModal();
        } catch (error) {
          console.error('Error sending message:', error);
        }
      };
      
    
    const getContactData = async () => {
        try {
          const response = await axios.get('getTickets', {
            headers: {
              'Content-Type': 'application/json',
              "X-custom-header": "$Ecur!tyHEadEr",
            "Authorization": localStorage.getItem("token"),
            },
          });
          return response.data.data.tickets;
        } catch (error) {
          console.error('Error fetching contact data:', error);
          return [];
        }
      };

      const postContactData = async (id, message) => {
        setFetchData(false)
        try {
          const response = await axios.post(`sendReply/${id}`, {message}, {
            headers: {
              'Content-Type': 'application/json',
              "X-custom-header": "$Ecur!tyHEadEr",
            "Authorization": localStorage.getItem("token"),
            },
          });
          if(response?.data?.statusCode === 200){
            toast.success(response?.data?.message);
            setFetchData(true)
            return response.data.data.tickets;
          }
        } catch (error) {
          console.error('Error sending reply:', error);
          return [];
        }
      };

      const postContactResponse = async (firstName, lastName, phone, email, hatsc,interest,state,message) => {
        try {
          const response = await axiosWeb.post('contactUs', { firstName, lastName, phone, email, hatsc,interest,state,message },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );
          return response.data.data.tickets;
        } catch (error) {
          console.error('Error sending reply:', error);
          return [];
        }
      };
    
    const contextValue = {
        contactData, setContactData,
        selectedID,setSelectedID,
        message, setMessage,
        openModal, closeModal,
        sendHandler, getContactData,
        postContactData,
        postContactResponse,fetchData

    };
  
    return (
      <ContactContext.Provider value={contextValue}>
        {children}
      </ContactContext.Provider>
    );
  };
