import React, {useState, useEffect, useRef} from "react";
import convertTime, { LinkData} from "Utils/static";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {useSettingsContext} from "../context/settingsContext"
import dropBtn from "../../src/assets/dropBtn.svg"


const Footer = () => {
  const { pathname } = useLocation();
  const {generalWebData} = useSettingsContext();
 const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const headerRef = useRef(null);



  const socialData = [
    {
      url: "/icon/1_icon.svg",
      link: generalWebData?.socialMediaLinks?.facebook,
      class:"fbIcon"
    },
    {
      url: "/icon/2_icon.svg",
      link: generalWebData?.socialMediaLinks?.linkedIn,
    },
    {
      url: "/icon/4_icon.svg",
      link: generalWebData?.socialMediaLinks?.twitter,
      class:"twitterFooterIcon"
    },
  ];


    const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

const handleSubMenuToggle = (index) => {
    setActiveSubMenu(index);
    setIsSubMenuOpen(true);
  };

  const handleSubMenuClose = () => {
    setActiveSubMenu(null);
    setIsSubMenuOpen(false);
  };

  return (
    <div className="bg-secondary text-white">
      <div className="outline-color ">
        <div className="flex flex-col md:flex-row items-center justify-between py-4 container">
          <div className="currentHover flex flex-wrap">
   
                     {LinkData?.map((menuItem, idx) => (
  
  <React.Fragment key={idx}>
    {menuItem.children ? (
      <div
        className={`relative group` }
        onMouseEnter={() => handleSubMenuToggle(idx)}
        onMouseLeave={handleSubMenuClose}
      >
        <span
          className={`cursor-pointer flex px-2 md:px-6 text-white text-sm text-small font-normal uppercase hoverColor ${
                      pathname === ( menuItem?.link) ? "currentActive" : ""
                      
          }`}
        >
          {menuItem.name}{" "}
          <span className="text-white">
            <img
              src={dropBtn}
              style={{ marginLeft: "5px" }}
              alt="dropdown"
              title="dropdown"
            />
          </span>
        </span>
        <div
          className={`absolute footerSubmenu headerSubmenu ${
            isSubMenuOpen && activeSubMenu === idx ? "block" : "hidden"
          } bg-black text-white py-2  space-y-2`}
          style={{ width: "80%" }}
        >
          {menuItem.children.map((child, childIdx) => (
            <Link
              to={child.link}
              relative="path"
              key={childIdx}
              className={`block text-white hover:text-gray-500 ${
                pathname === child.link ? "currentActive" : ""
              }`}
            >
              {child.name}
            </Link>
          ))}
        </div>
      </div>
    ) : (
      <Link
        to={menuItem.link}
        relative="path"
        className={
          pathname === menuItem.link ||
          (isSubMenuOpen && menuItem.children?.some(submenu => submenu.link === pathname))
            ? "active currentActive px-2 md:px-6 text-white text-sm text-small font-normal uppercase"
            : "px-2 md:px-6 text-white text-sm text-small font-normal uppercase"
        }
      >
        {menuItem.name}
      </Link>
    )}
  </React.Fragment>
))}
          </div>
          <div className="flex items-center md:mt-0 mt-4">
            <span className="text-base">Follow Us:</span>
            {socialData.map((val, idx) => (
               <a href={val.link} target='_blank' key={idx}>
              <img
                  className={`cursor-pointer px-2.5 ${val.class}`}
                src={val.url}
                  alt="social_icon"
                  title="social_icon"
              />
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center w-screen mt-8 md:mt-12 ">
       
         <Link
            to="/"
        
        >
           <img
          className="cursor-pointer footer-logo"
          src="/logo.svg"
            alt="Topshelfconstructionusa - logo"
            title="logo"
          style={{
            height: '90px'
          }}
        />
          </Link>
        <div className="md:text-lg text-sm my-4 font-secondary text-white font-bold footerNumber" style={{fontSize:"2.5rem"}}>
          {generalWebData?.phone}
        </div>
        <div className="text-center mt-2 text-xs md:text-sm">
          SALES OFFICE<br /> {generalWebData?.address}{" "}
          <br />
          Opening Hours: <span style={{textTransform:"uppercase"}}> { generalWebData?.openingHours}  — {generalWebData?.closingHours} {generalWebData?.timezone}</span>
        </div>
      </div>
      <div style={{backgroundColor:"#2d2d2d"}}>
      <div className="container flex flex-col md:flex-row items-center justify-between py-4 text-xs md:text-sm mt-4 text-gray-300" >
        <div className="text-white">© {new Date().getFullYear()} TopShelf Construction. All Right Reserved.</div>
        <div>
          <Link
            to="/privacy-policy"
            relative="path"
            className="px-2 md:px-4 text-white hoverColor"
          >
            Privacy Policy
          </Link>
          <Link
            to="/cookies-policy"
            relative="path"
            className="px-2 md:px-4 text-white hoverColor"
          >
            Cookies
          </Link>
        </div>
        </div>
        </div>
    </div>
  );
};

export default Footer;
