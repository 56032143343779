import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../index.css"
import "tailwindcss/tailwind.css"; 

const Index = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default Index;
