import React, { useState, useContext, useRef } from 'react';

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [isToggle, setIsToggle] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [active, setActive] = useState(null)
  const scrollToRef = useRef();



  const toggle = () => {
    setIsToggle(!isToggle);
  };



  return (
    <AppContext.Provider
      value={{
        isToggle,
        toggle,
        isAuthenticated,
        active,
        setActive,
        setIsAuthenticated,
        scrollToRef
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export { AppContext, AppProvider };
