import React, { createContext, useContext, useState } from 'react';
import axios from '../Utils/APIS/axiosConfig';
import axiosWeb from '../Utils/APIS/axiosWebConfig'

const SettingsContext = createContext();

export const useSettingsContext = () => useContext(SettingsContext);

export const SettingsProvider = ({ children }) => {
    const [generalData, setGeneralData] = useState(null);
    const [generalWebData, setGeneralWebData] = useState(null);
    const [inData, setInData] = useState([])

    const updateGeneralSettings = (data) => {
      const config = {
        method: 'patch',
        url: `updateGeneralSetting`,
        headers: {
          'Content-Type': 'application/json',
          "X-custom-header": "$Ecur!tyHEadEr",
            "Authorization": localStorage.getItem("token"),
        },
        data: data,
      };
  
      return axios.request(config);
    };
    
    const getGeneralSettings = () => {
        const config = {
          method: 'get',
          url: `getGeneralSetting`,
          headers: {
            "X-custom-header": "$Ecur!tyHEadEr",
            "Authorization": localStorage.getItem("token"),
            'Content-Type': 'application/json',

          },
        };
        return axios.request(config);
    };
    
    const getWebGeneralSettings = async() => {
      const config = {
        method: 'get',
        url: `/getGeneralSetting`,
        
      };
      return axiosWeb.request(config);
  };

    const contextValue = {
      generalData,
      setGeneralData,
      updateGeneralSettings,
      getGeneralSettings,
      getWebGeneralSettings,
      inData,
      setInData,
      generalWebData, setGeneralWebData,
    };
  
    return (
      <SettingsContext.Provider value={contextValue}>
        {children}
      </SettingsContext.Provider>
    );
  };
