import PropertyDetail from "pages/HomeForSale/PropertyDetail";
import { lazy } from "react";
import { Navigate, useRoutes} from "react-router-dom";
const Dashboard = lazy(() => import("pages/dashboard"));
const About = lazy(() => import("pages/about"));
const Contact = lazy(() => import("pages/ContactUs"));
const Portfolio = lazy(() => import("pages/portfolio"));
const HomeForSale = lazy(() => import("pages/HomeForSale"));
// const Testimonials = lazy(() => import("pages/Testimonials"));
const Virtual = lazy(() => import("pages/Virtual"));
const Services = lazy(() => import("pages/services"));
const NotFoundPage = lazy(() => import("pages/notFound"));
const Finaching = lazy(() => import("pages/Finaching"));
const Promotion = lazy(() => import("pages/Promotion"));
const Cookies = lazy(() => import("pages/Cookies"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyPolicy"));




const CMSDashboard = lazy(() => import("../CMS-Panel/pages/CMSDashboard/CMSDashboard"));
const GeneralSettings = lazy(() => import("../CMS-Panel/pages/GeneralSettingsPage/GeneralSettings"));
const AboutPage = lazy(() => import("../CMS-Panel/pages/TeamMemberPage/TeamMemberPage"));
const ContactPage = lazy(() => import("../CMS-Panel/pages/ContactPage/ContactPage"));
const PortfolioPage = lazy(() => import("../CMS-Panel/pages/PortfolioPage/PortfolioPage"));
const LoginPage = lazy(() => import("../CMS-Panel/pages/LoginPage/LoginPage"));
const BannerPage = lazy(() => import("../CMS-Panel/pages/BannerPage/BannerPage"));





const AppRoutes = () => {
  const isAuthenticated = localStorage.getItem("token") ? true : false;

  const routes = [
    { path: "/", element: <Dashboard /> },
    { path: "/login", element: <LoginPage /> },
    // { path: "/home-for-sale", element: <HomeForSale /> },
    // { path:"/home-for-sale/:id",  element: <PropertyDetail />},
    { path: "/about-us", element: <About /> },
    { path: "/contact-us", element: <Contact /> },
    { path: "/gallery", element: <Portfolio /> },
    // { path: "/testimonials", element: <Testimonials /> },
    { path: "/virtual-tour", element: <Virtual /> },
    { path: "/services", element: <Services /> },
    { path: "/financing", element: <Finaching /> },
    { path: "/promotions", element: <Promotion /> },
    { path: "/cookies-policy", element: <Cookies /> },
    { path: "/privacy-policy", element: <PrivacyPolicy /> },
    { path: "/not", element: <NotFoundPage /> },
    { path: "*", element: <Navigate to="/" /> },
    isAuthenticated && {path: "/cms-dashboard", element: <CMSDashboard /> },
    isAuthenticated && {path: "/banner-images", element: <BannerPage /> },
    isAuthenticated && { path: "/general-settings", element: <GeneralSettings /> },
    isAuthenticated && { path: "/team-members", element: <AboutPage /> },
    isAuthenticated && { path: "/contact-page", element: <ContactPage /> },
    isAuthenticated && { path: "/gallery-page", element: < PortfolioPage /> }
  ];
  return useRoutes(routes)
};

export default AppRoutes;



