import React, {useState, useEffect, useRef} from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Button from "Component/Common/Button";
import { LinkData} from "Utils/static";
import { useNavigate } from "react-router-dom";
import {useSettingsContext} from "../context/settingsContext"
import HeaderModal from "Component/HeaderModal/HeaderModal";
import HeaderSidebar from "Component/HeaderModal/HeaderSidebar";
import Sidenav from "Component/HeaderModal/HeaderSidebar";
import dropBtn from "../../src/assets/dropBtn.svg"



const Header = () => {
const { pathname } = useLocation();
  const navigate = useNavigate();
  const { generalWebData, setGeneralWebData, getWebGeneralSettings } = useSettingsContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  
  const toggle = () => setIsMenuOpen(!isMenuOpen);

  const loadData = async () => {
    try {
      const response = await getWebGeneralSettings();
      if (response.data?.statusCode === 200) {
        setGeneralWebData(response?.data?.data?.setting);
      }
    } catch (error) {
      console.log("Error fetching general settings data");
    }
  };

  const socialData = [
    {
      url: "/icon/1_icon.svg",
      link: generalWebData?.socialMediaLinks?.facebook,
      class:"fbIcon"
    },
    {
      url: "/icon/2_icon.svg",
      link: generalWebData?.socialMediaLinks?.linkedIn,
    },
    {
      url: "/icon/4_icon.svg",
      link: generalWebData?.socialMediaLinks?.twitter,
      class: "twitterHeaderIcon",
    },
  ];

  useEffect(() => {
    loadData();
  }, []);

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

const handleSubMenuToggle = (index) => {
    setActiveSubMenu(index);
    setIsSubMenuOpen(true);
  };

  const handleSubMenuClose = () => {
    setActiveSubMenu(null);
    setIsSubMenuOpen(false);
  };
  return (
    <>
      <div className="newHeader hiddenClass">
         <div className="text-white md:container spaceClass">
      <div className="flex items-center justify-end py-3 flex-wrap hiddenClass ">
        <span className="cursor-pointer px-2.5 text-base">
        {generalWebData?.email}
        </span>
        <span className="cursor-pointer px-2.5">{generalWebData?.phone}</span>
        <div className="hidden md:flex items-center">
        {socialData.map((val, idx) => (
          <a href={val.link} target='_blank' key={idx}>
          <img
         className={`cursor-pointer px-2.5 ${val.class}`}
            src={val.url}
              alt="social_icon"
              title="social_icon"
          />
          </a>
        ))}
          </div>
          
          </div>
          </div>
        </div>
    <div className="bg-secondary text-white md:container spaceClass">
      <div className="flex items-center justify-between py-4">
      <div className="md:hidden cursor-pointer " onClick={handleMenuClick}>
          <img className="cursor-pointer" src="/icon/hambergerlogo.svg" alt="hamberger" title="hamberger" />
        </div>
        <span onClick={() => { navigate("/"); }}>
          <img className="cursor-pointer ctaborderRds" src="/logo.svg" alt="Topshelfconstructionusa - logo" title="logo" />
        </span>
        <div className="invisible md:visible"></div>
        <div className="hidden md:flex items-center currentHover">
          {LinkData?.map((menuItem, idx) => (
  
  <React.Fragment key={idx}>
    {menuItem.children ? (
      <div
        className={`relative group` }
        onMouseEnter={() => handleSubMenuToggle(idx)}
        onMouseLeave={handleSubMenuClose}
      >
        <span
          className={`cursor-pointer flex px-2 md:px-6 text-white text-sm text-small font-normal uppercase hoverColor ${
                      pathname === ( menuItem.link) ? "currentActive" : ""
                      
          }`}
        >
          {menuItem.name}{" "}
          <span className="text-white">
            <img
              src={dropBtn}
              style={{ marginLeft: "5px" }}
              alt="dropdown"
              title="dropdown"
            />
          </span>
        </span>
        <div
          className={`absolute headerSubmenu ${
            isSubMenuOpen && activeSubMenu === idx ? "block" : "hidden"
          } bg-black text-white py-2  space-y-2`}
          style={{ width: "80%" }}
        >
          {menuItem.children.map((child, childIdx) => (
            <Link
              to={child.link}
              relative="path"
              key={childIdx}
              className={`block text-white hover:text-gray-500 ${
                pathname === child.link ? "currentActive" : ""
              }`}
            >
              {child.name}
            </Link>
          ))}
        </div>
      </div>
    ) : (
      <Link
        to={menuItem.link}
        relative="path"
        className={
          pathname === menuItem.link ||
          (isSubMenuOpen && menuItem.children?.some(submenu => submenu.link === pathname))
            ? "active currentActive px-2 md:px-6 text-white text-sm text-small font-normal uppercase"
            : "px-2 md:px-6 text-white text-sm text-small font-normal uppercase"
        }
      >
        {menuItem.name}
      </Link>
    )}
  </React.Fragment>
))}

          <Button
            buttonText="Contact US"
            onClickHandler={() => {
              navigate("/contact-us");
              }}
              className="ctaborderRds"
          />
        </div>
      </div>
      <HeaderModal isMenuOpen={isMenuOpen} toggle={toggle} />
      </div>
      </>
  );
};

export default Header;
