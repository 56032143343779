import React, {useRef} from "react";
import VerticalTitle from "Component/Common/VerticalTitle";
import { Formik } from "formik";
import Button from "Component/Common/Button";
import { tabInfo } from "../../Utils/static"
import { useContactContext } from "context/contactContext";
import { useGlobalContext } from "context/context";
import { toast } from "react-toastify";
import QRCODE from "../../assets/QR.png"


const Index = ({ selectedTabId, QRcode }) => {
  let FormData = {
    email: "",
    firstName: "",
    lastName:"",
    message: "",
    phone: "",
    hatsc:"",
    interest: "",
    state:"",
  };
  // const scrollToRef = useRef();
  const {postContactResponse} = useContactContext()
  const {scrollToRef} = useGlobalContext()

  
    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await postContactResponse(
        values.firstName,
      values.lastName,
      values.phone,
      values.email,
      values.hatsc,
      values.interest,
      values.state,
      String(values.message)
      );
      toast.success('Thank you for reaching out us! We will contact you soon...');
      resetForm()
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('An error occurred while sending the message.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
          <VerticalTitle title="Contact" />
    <div className="container my-12" ref={scrollToRef}>
        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-12 gap-0">
        <div className="flex">
          <div>
            <div className="md:text-3xl text-lg font-semibold textCenter">
              <span className="text-primary">Let’s Talk</span> about your needs and aspirations
            </div>
            <div>
              {tabInfo.map((val) => {
                if (val.id === selectedTabId) {
                  return (
                    <div key={val.id}>
                      <div className="text-base">{val.info}</div>
                    </div>
                  );
                }
                return null
              })}
              </div>
              {QRcode &&
                <div className="flex my-5 items-center flex-wrap">
                  <img src={QRCODE} className="QrCODE"
                    alt="Topshelfconstructionusa - scan QR code"
                    title="Topshelfconstructionusa - scan QR code"
                    onClick={() => {
                      window.open("https://www.truviewlending.com/", "_blank");
                    }}
                  />
                <div className="scanText">Scan the QR code or click here for comprehensive details regarding finance</div>
              </div>
             }
          </div>
        </div>
            {/* <div className="builderTrendForm">
           <iframe
      src="https://buildertrend.net/leads/contactforms/ContactFormFrame.aspx?builderID=102404"
      scrolling="no"
      id="btIframe"
      style={{
        background: 'transparent',
        border: '0px',
        margin: '0 auto',
        width: '100%',

      }}
      title="Contact Form"
            />
            </div> */}
    <Formik
            initialValues={FormData}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Email is required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (!values.phone) {
                errors.phone = "Phone Number is required";
              }
              if (!values.firstName) {
                errors.firstName = "First Name is required";
              }
              if (!values.lastName) {
                errors.lastName = "First Name is required";
              }
               if (!values.hatsc) {
                errors.hatsc = "This field is required";
              }
               if (!values.interest) {
                errors.interest = "This field is required";
              }
              if (!values.state) {
                errors.state = "State Name is required";
              }
              if (!values.message) {
                errors.message = "Message is required";
              }
              return errors;
            }}
        onSubmit={(values, { setSubmitting, resetForm  }) => {
              setTimeout(() => {
                handleSubmit(values, {setSubmitting, resetForm});
                setSubmitting(false);
              }, 500);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form
                className="grid grid-cols-2 gap-6 mt-4 contact-us-form-input contactUSform"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(values);
                }}
              >
               <input
                  className="col-span-2 md:col-span-1 custom-input"
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  style={{
                    border:
                      errors.firstName && touched.firstName && "2px solid red",
                  }}
                />
                 <input
                  className="col-span-2 md:col-span-1 custom-input"
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  style={{
                    border:
                      errors.lastName && touched.lastName && "2px solid red",
                  }}
                />
                <input
                  className="col-span-2 md:col-span-1 custom-input"
                  id="phone"
                  type="number"
                  placeholder="Phone Number"
                  name="phone"
                  min={0}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.phone}
                  style={{
                    border: errors.phone && touched.phone && "2px solid red",
                  }}
                />
                 <input
                  type="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  id="email"
                  placeholder="Email Address"
                  style={{
                    border: errors.email && touched.email && "2px solid red",
                  }}
                  className="col-span-2 md:col-span-1 custom-input"
                />
                     <select
                className="col-span-2  custom-input dropdown-button"
                id="interest"
                name="interest"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.interest}
                style={{
                  border:
                    errors.interest && touched.interest && "2px solid red",
                }}
              >
                <option value="" disabled >What Are You Interested In</option>
                <option value="New-Construction">New-Construction Homes for Sale</option>
                <option value="Custom-Homes">Custom Homes</option>
                <option value="Remodel">Remodel</option>
                <option value="General-Information">General Information</option>
                <option value="Financing">Financing</option>
                  </select>
                   <select
                className="col-span-2  custom-input dropdown-button"
                id="state"
                name="state"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.state}
                style={{
                  border:
                    errors.state && touched.state && "2px solid red",
                }}
              >
               <option value="" disabled >Select State</option>
                <option value="South Dakota">South Dakota</option>
                <option value="North Dakota">North Dakota</option>
                <option value="Arizona">Arizona</option>
                <option value="Other">Other</option>
                </select>
                    <select
                className="col-span-2  custom-input dropdown-button"
                id="hatsc"
                name="hatsc"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.hatsc}
                style={{
                  border:
                    errors.hatsc && touched.hatsc && "2px solid red",
                }}
              >
               <option value="" disabled >How did you hear about TSC</option>
                <option value="Instagram">Instagram</option>
                <option value="Facebook">Facebook</option>
                <option value="Top Shelf Advertising">Top Shelf Advertising</option>
                <option value="Past Client referral">Past Client referral</option>
                <option value="Family and friends">Family and friends</option>
                <option value="Other">Other</option>
                </select>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Write your message here"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.message}
                  style={{
                    border:
                      errors.message && touched.message && "2px solid red",
                  }}
                  className="col-span-2 custom-textarea"
                  rows="5"
                />
                <Button
                    buttonText="Submit"
                    className="textCenterBtn ctaborderRds col-span-2 "
                  onClickHandler={handleSubmit}
                  disabled={isSubmitting}
                  type="submit"
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
      </>
  );
};

export default Index;
