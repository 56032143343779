import { toast } from "react-toastify";
// export const LinkData = [
//   {
//     name: "Home",
//     link: "/home",
//   },
//   {
//     name: "About Us",
//     link: "/about-us",
//   },
//   {
//     name: "Services",
//     link: "/services",
//   },
//   {
//     name: "Portfolio",
//     link: "/portfolio",
//   },
//   {
//     name: "Contact Us",
//     link: "/contact-us",
//   },
//   // {
//   //   name: "Testimonials",
//   //   link: "/testimonials",
//   // },
// ];
export const LinkData = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Services",
    link: "/services",
  },
  {
    name: "Gallery",
    link: "/gallery",
     children: [
      {
        name: "Gallery",
        link: "/gallery",
      },
      {
        name: "Virtual Tour",
        link: "/virtual-tour",
      },
    ],
  },
  {
    name: "Home Financing ",
    link: "/financing",
    children: [
      {
        name: "Financing",
        link: "/financing",
      },
      {
        name: "Promotions",
        link: "/promotions",
      },
    ],
  },
  // {
  //  name: "Testimonials",
  //  link: "/testimonials",
  //  },
];



export const ourTeam = [
  {
    profilePic: "https://source.unsplash.com/mEZ3PoFGs_k/200x200",
    name: "John Doe",
    role: "Sr. Software Dev",
    faceBook: "https://www.facebook.com/",
    linkedin: "https://www.facebook.com/",
    twitter: "https://www.facebook.com/",
  },
  {
    profilePic: "https://source.unsplash.com/7omHUGhhmZ0/200x200",
    name: "John Doe",
    role: "Sr. Software Dev",
    faceBook: "https://www.facebook.com/",
    linkedin: "https://www.facebook.com/",
    twitter: "https://www.facebook.com/",
  },
  {
    profilePic: "https://source.unsplash.com/iFgRcqHznqg/200x200",
    name: "John Doe",
    role: "Sr. Software Dev",
    faceBook: "https://www.facebook.com/",
    linkedin: "https://www.facebook.com/",
    twitter: "https://www.facebook.com/",
  },
  {
    profilePic: "https://source.unsplash.com/rDEOVtE7vOs/200x200",
    name: "John Doe",
    role: "Sr. Software Dev",
    faceBook: "https://www.facebook.com/",
    linkedin: "https://www.facebook.com/",
    twitter: "https://www.facebook.com/",
  },
];

export const portfolioTabs = [
  "All Projects",
  "Town Homes",
  "Condos",
  "Full Home Remodels",
  "Fine Carpentry",
  "Custom affordable homes",
  "Custom high end homes"
];

export const tabInfo = [
  {
    id: "1",
    name: "Home",
    info: "Ready to transform your space? We're here to listen, plan, and create. Contact TopShelf Construction to embark on your construction journey with a team that values your aspirations. Your project is our passion, and we're excited to collaborate with you every step of the way.",
  },
  {
    id: "2",
    name: "About us",
    info: "Connect with TopShelf Construction - Your Partner in Crafting Remarkable Spaces. From envisioning to executing, we're here to transform your ideas into reality. Reach out today to start your construction journey with a team dedicated to excellence and innovation. Let's build something extraordinary together."
  },
  {
    id: "3",
    name: "Services",
    info: "Reach Out and Build with Us. Have questions, ideas, or dreams you want to bring to life? We're here to listen and collaborate. Contact TopShelf Construction now to begin your construction adventure with a team that's committed to making your vision a reality. Let's start building the future you've imagined."
  },

]

export default function convertTime(x) {
  if(!x) return
  var [h, m] = x.split(":");
  var time = ((h % 12 ? h % 12 : 12) + ":" + m, h >= 12 ? 'PM' : 'AM')
   return time;
}


export function checkImgValidation(filesize) {
   const fsize = filesize.size;
    const file = Math.round((fsize / 1024));
  if (file >= 1024) {
    toast.error("Image size should be less than 1MB")
    return false;
  } else return true;
}



 