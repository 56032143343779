const propertiesData = [
    {
      id: "1",
      title: "Elk Horn lot 14",
      price: "$1.05M",
      location: "Stunning Golf Course Home in Spearfish,",
      state: "South Dakota",
      status: "coming soon!",
      images: [{ image1: "/homeforsale/prop14_1.svg", image2: "/homeforsale/prop14_2.svg" }],
      description: (
        <>
          Welcome to this breathtaking residence nestled in the heart of Elk Horn Ranch, Spearfish, South Dakota. This exceptional home, boasting 3,585 square feet of living space, is perfectly positioned on the pristine Elk Horn Ranch Golf Course, offering serene views and exclusive access to your very own 421 square-foot golf course garage, seamlessly attached to the house.
         <br /> <br />
          Upon entering the main level, you'll be greeted by a spacious and inviting layout. The master suite is a true retreat, complete with a luxurious full bathroom. An additional bedroom and a conveniently located half bathroom provide ample space for family or guests. The living room and dining room areas flow effortlessly into a large, well-appointed kitchen featuring an expansive pantry. The mudroom ensures an organized entryway, while the covered deck off the back patio invites you to enjoy outdoor living at its finest.
         <br /> <br />
          This home also includes an oversized, 1,350 square-foot, three-stall garage with extra storage space, catering to all your storage needs.
         <br /> <br />
          Descend to the finished lower level, where you'll find another cozy living room and two additional bedrooms, offering a perfect blend of comfort and functionality.
         <br /> <br />
          Priced at $1.050 million, this exquisite home is a rare find. Contact TopShelf Construction today for more information on making this beautiful home yours!
         <br /> <br />
        </>
      ),
      keyPoints: ["3,585 SF", "1,350 SF Garage", "421 SF Golf Cart garage", "4 Bedrooms 2 ½ Bathrooms", "Currently being built (Coming soon)", "Located in Spearfish South Dakota in Elk Horn Estates"],
    },
     {
      id: "2",
      title: "Elk horn lot 15",
      price: "$1.1M",
      location: "Luxurious New Construction in Elk Horn Estates, Spearfish,",
      state: "South Dakota",
      status: "",
      images: [{ image1: "/homeforsale/prop15_1.svg", image2: "/homeforsale/prop15_2.svg" }],
      description: (
        <>
          Discover your dream home at Elk Horn Estates, a prestigious new construction located on the picturesque Elk Horn Ranch Golf Course in Spearfish, South Dakota. This expansive 3,844 square-foot residence, priced at $1.1 million, offers an abundance of space and sophisticated design to meet all your living needs.
         <br /> <br />
          The home's layout is thoughtfully designed to provide both functionality and elegance. The main level features a lavish primary suite, complete with an on-suite bathroom boasting a large walk-in shower for your ultimate comfort. The spacious kitchen, equipped with a central island, is perfect for culinary enthusiasts, while the dining room and expansive living room create a seamless flow for entertaining and everyday living. Additionally, two covered patios off the main level offer spaces for outdoor relaxation and dining.
         <br /> <br />
          The basement level is an entertainer's paradise, featuring three well-appointed bedrooms and two full bathrooms. A large living room, complete with a cozy fireplace and a wet bar, provides a perfect setting for gatherings and celebrations.
         <br />  <br /> 
          Storage and convenience are not overlooked, with a large Three-stall garage providing ample space for vehicles and additional storage.
         <br /> <br />
          Currently under construction, this stunning home presents a unique opportunity to customize and make it truly yours. For more information and to explore the possibilities, contact TopShelf Construction today!
         <br /> <br />
        </>
      ),
      keyPoints: ["$1.1 Million", "3,844 SF", "4 Bedrooms and 3 ½ Bathrooms", "3 car oversized garage that is 1,391 SF with extra storage above garage"],
    },
     {
      id: "3",
      title: "Rapid City lot 26",
      price: "$675K",
      location: "Elegant New Construction in Elk Country Estates, Rapid City,",
      state: "South Dakota",
      status: "",
      images: [{ image1: "/homeforsale/prop26_1.svg", image2: "/homeforsale/prop26_2.svg" }],
      description: (
        <>
          Welcome to your future home in the charming and well-established neighborhood of Elk Country Estates, Rapid City, South Dakota.
          <br /> <br />
          Nestled near the beautiful Elks Country Estates Golf Course, this new build offers modern living within a vibrant community.
         <br /> <br />
          This residence features a thoughtfully designed main level complemented by an expansive unfinished basement, providing endless possibilities for customization. The main level spans 1,450 square feet of finished living space, highlighting quality craftsmanship and attention to detail throughout.
         <br /> <br />
          The master bedroom serves as a luxurious retreat, complete with an on-suite bathroom. Pamper yourself in the spacious walk-in shower or relax in the freestanding bathtub. The suite also includes a generously sized walk-in closet, ensuring ample storage.
          <br /> <br />
          Across the hall, you'll find a second bedroom and an additional well-appointed bathroom, making this home perfect for families or guests. The large living room seamlessly flows into the dining area and the modern kitchen, which features a central island ideal for meal preparation and casual dining.
         <br /> <br />
          An 822 square-foot, three-car garage offers space for vehicles and storage, while the 1,312 square-foot unfinished basement provides a blank canvas for your future needs, be it additional living space, a home gym, or a recreation room.
         <br /> <br />
          Currently under construction, this exquisite 2-bedroom, 2-bathroom home is a rare opportunity to enjoy contemporary living in a desirable location. To learn more and make this house your own, contact TopShelf Construction today!
         <br /> <br />
        </>
      ),
      keyPoints: ["$675K", "Main Floor Finished space 1,450 SF", "1,312 SF Unfinished Basement", "822 SF Garage", "2 Bedrooms", "2 Bathrooms"],
    },
];
  
export default propertiesData;