import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from '../Utils/APIS/axiosConfig';
import axiosWeb from '../Utils/APIS/axiosWebConfig'

const PortfolioContext = createContext();

export const usePortfolioContext = () => useContext(PortfolioContext);

export const PortfolioContextProvider = ({ children }) => {
    const [isTabs, setIsTabs] = useState([{id:'allProjects',name:'All Projects'}])
    const [activeTab, setActiveTab] = useState({name:"All Projects", id:'allProjects'})
    const [categoryImage, setCategoryImage] = useState(null)
    const [allProject, setAllProject] = useState([])
    const [showMore, setShowMore] =useState(true)
  
    const getImagesAPI = async (id) => {
        const config = {
          method: 'get',
          url: `getPortfolio?portfolioId=${id}`,
        };
        return axiosWeb.request(config)
          .then((response) => {
            if (response?.data?.statusCode === 200) {
              const value = response.data?.data?.portfolio;
              const imageData = value.map((item) => item.image);
              return imageData
            } else {
              console.error(
                response?.data?.message || "Something went wrong try again later."
              );
            }
          })
          .catch((error) => {
            console.log("Error: Data is not fetched", error);
          });
      };

      const getAllImagesAPI = async () => {
        const config = {
          method: 'get',
          url: `getPortfolio`,
        };
        return axiosWeb.request(config)
          .then((response) => {
            if (response?.data?.statusCode === 200) {
              const value = response.data?.data?.portfolio;
              const imageData = value.map((item) =>({
                img :item.image,
                category : item.categoryName}));
              return imageData
            } else {
              console.error(
                response?.data?.message || "Something went wrong try again later."
              );
            }
          })
          .catch((error) => {
            console.log("Error: Data is not fetched", error);
          });
      };
      

    const getAllTabs = async () => {
        try {
            const response = await axiosWeb.get(`getPortfolio?portfolioId=`);
            const categories = response?.data?.data?.categories;
            const tabData = categories.map((category) => ({
                id: category._id,
                name: category.name
            }));
            return tabData
        } catch (error) {
            console.error('Error fetching tabs:', error);
        }
    };
      
    const contextValue = {
    isTabs, setIsTabs, getAllTabs,
    activeTab,setActiveTab,
    categoryImage, setCategoryImage,
    allProject, setAllProject,
    getImagesAPI, getAllImagesAPI,
    showMore, setShowMore
    };
  
    return (
      <PortfolioContext.Provider value={contextValue}>
        {children}
      </PortfolioContext.Provider>
    );
  };
